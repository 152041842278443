import { ChatModel } from './chatModels';
import React, { useState, useEffect, useRef } from 'react';
import CustomDropdown from './CustomDropdown';
import { Sun, Moon, Monitor, CaseSensitive } from 'lucide-react';
import { useTheme } from './ThemeContext';
import { useFont } from './FontContext';
import Select from 'react-select';

const Toolbar = ({
  toggleSidebar,
  onNewChat,
  chatTitle,
  model,
  isSidebarOpen,
  selectedModel,
  setSelectedModel,
  sidebarWidth,
  hasSubscription,
  hasBasicSubscription
}) => {
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showNewChatTooltip, setShowNewChatTooltip] = useState(false);
  const [showFontTooltip, setShowFontTooltip] = useState(false);
  const [showFontDropdown, setShowFontDropdown] = useState(false);
  const { theme, setTheme } = useTheme();
  const { currentFont, setCurrentFont } = useFont();

  const buttonStyle = "h-8 w-8 flex items-center justify-center rounded-full transition-colors duration-200";
  const imageStyle = "h-5 w-auto";
  const imageStyle2 = "h-3 w-auto";

  const modelOptions = Object.values(ChatModel).map(model => ({
    display: `${model.name}`,
    value: model.modelString,
    provider: model.provider,
    isPremium: model.isPremium
  }));

  const getModelDetails = (modelString) => {
    return Object.values(ChatModel).find(m => m.modelString === modelString) ||
      Object.values(ChatModel).find(m => m.name.toLowerCase() === modelString.toLowerCase());
  };
  const modelDetails = getModelDetails(model || selectedModel);
  const displayModelName = modelDetails ? modelDetails.name : 'Unknown Model';
  const modelColor = modelDetails ? modelDetails.color : '#808080';
  const isNewChat = !chatTitle || chatTitle === 'New Chat';
  const isSettings = !chatTitle || chatTitle === 'Settings';

  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      if (prevTheme === 'light') return 'dark';
      if (prevTheme === 'dark') return 'system';
      return 'light';
    });
  };

  const getThemeIcon = () => {
    switch (theme) {
      case 'light': return <Sun size={16} />;
      case 'dark': return <Moon size={16} />;
      case 'system': return <Monitor size={16} />;
      default: return <Sun size={16} />;
    }
  };

  const themeButtonStyle = `${buttonStyle} ${isDarkMode ? 'text-white' : 'text-gray-800'}`;

  // Font options
  const fonts = [
    { name: 'PT Serif', className: 'font-serif' },
    { name: 'Inter', className: 'font-sans' },
    { name: 'Funnel Display', className: 'font-sans' },
    { name: 'Sour Gummy', className: 'font-sans' },
    { name: 'DynaPuff', className: 'font-sans' },
    { name: 'Yusei Magic', className: 'font-sans' },
  ];

  const fontOptions = fonts.map((font) => ({
    value: font.name,
    label: font.name,
  }));

  const darkBg = '#242424';
  const darkBgHover = '#1e1e1e';
  const lightBg = '#dbdbd7';
  const lightBgHover = '#c7c7c3';
  const darkText = '#CBD5E0';
  const lightText = '#4A5568';

  const customStyles = {
    container: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
      position: 'absolute',
      top: '2.5rem',
      right: 0,
      zIndex: 9999,
      width: 200
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      borderColor: isDarkMode ? '#4A5568' : '#CBD5E0',
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        borderColor: isDarkMode ? '#718096' : '#A0AEC0',
      },
      boxShadow: state.isFocused ? (isDarkMode ? '0 0 0 1px #718096' : '0 0 0 1px #A0AEC0') : null,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      backgroundColor: state.isFocused
        ? isDarkMode ? darkBgHover : lightBgHover
        : isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
      cursor: 'pointer'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      color: isDarkMode ? darkText : lightText,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#718096' : '#A0AEC0',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        color: isDarkMode ? '#FFFFFF' : '#2D3748',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
    }),
  };

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowFontDropdown(false);
    }
  };

  useEffect(() => {
    if (showFontDropdown) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showFontDropdown]);

  return (
    <div className="flex items-center bg-opacity-00 dark:bg-opacity-0  p-4 absolute top-0 left-0 right-0 z-10 h-16  transition-colors duration-200">
      <div className="flex items-center" style={{ width: isSidebarOpen ? `${sidebarWidth}px` : 'auto' }}>
        <div className="relative ml-1">
          <button
            onClick={toggleSidebar}
            onMouseEnter={() => setShowSidebarTooltip(true)}
            onMouseLeave={() => setShowSidebarTooltip(false)}
            className={buttonStyle}
          >
            <img
              src={isDarkMode ? "/sidebar.left.darkmode.png" : "/sidebar.left.lightmode.png"}
              alt={isSidebarOpen ? "Hide sidebar" : "Show sidebar"}
              className={imageStyle}
            />
          </button>
          {showSidebarTooltip && (
            <div className="absolute left-0 mt-2 py-1 px-2 dark:bg-[rgb(229,229,225)] bg-[rgb(39,39,39)] dark:text-black text-white text-xs rounded whitespace-nowrap">
              {isSidebarOpen ? "Hide sidebar" : "Show sidebar"}
            </div>
          )}
        </div>
        <div className="relative ml-auto mr-3">
          <button
            onClick={onNewChat}
            onMouseEnter={() => setShowNewChatTooltip(true)}
            onMouseLeave={() => setShowNewChatTooltip(false)}
            className={buttonStyle}
          >
            <img
              src={isDarkMode ? "/square.and.pencil.darkmode.png" : "/square.and.pencil.lightmode.png"}
              alt="New Chat"
              className={"h-6 w-auto"}
            />
          </button>
          {showNewChatTooltip && (
            <div className="absolute right-0 mt-2 py-1 px-2 dark:bg-[rgb(229,229,225)] bg-[rgb(39,39,39)] dark:text-black text-white text-xs rounded">
              New Chat
            </div>
          )}
        </div>
      </div>
      <div className={`flex items-center ${isSidebarOpen ? 'ml-[40px]' : ''} flex-grow`}>  <div className="flex-grow"></div>
        <div className="flex items-center">
          {!isNewChat && !isSettings && (
            <>
              <h1 className="text-xl font-semibold text-black dark:text-white mr-4">
                {chatTitle}
              </h1>
              <span
                className="px-3 py-1 text-sm rounded-full text-[rgb(250,250,250)] dark:text-[rgb(255,255,255)]"
                style={{
                  backgroundColor: modelColor,
                  opacity: 1.0,
                  transition: 'background-color 0.3s'
                }}
              >
                {displayModelName}
              </span>
            </>
          )}
          {isNewChat && !isSettings && (
            <CustomDropdown
              options={modelOptions}
              value={selectedModel}
              onChange={setSelectedModel}
              hasSubscription={hasSubscription}
              isDarkMode={isDarkMode}
            />
          )}
          {isSettings && (
            <h1 className="text-xl font-semibold text-black dark:text-white mr-4">
              {chatTitle}
            </h1>
          )}
        </div>
      </div>

      <div className="flex-grow" />
      <div className="flex items-center">
      <div className="relative  ml-2" ref={dropdownRef}>
        <button
          onClick={() => {
            setShowFontDropdown(!showFontDropdown);
            setShowFontTooltip(false);
          }}
          onMouseEnter={() => setShowFontTooltip(true)}
          onMouseLeave={() => setShowFontTooltip(false)}
          className={buttonStyle}
          title="Select Font"
        >
          <img
            src={isDarkMode ? "/textformat.darkmode.png" : "/textformat.lightmode.png"}
            alt={isSidebarOpen ? "Hide sidebar" : "Show sidebar"}
            className={imageStyle2}
          />
        </button>
        {showFontTooltip && (
          <div className="absolute right-0 mt-2 py-1 px-2 dark:bg-[rgb(229,229,225)] bg-[rgb(39,39,39)] dark:text-black text-white text-xs rounded">
            Change font
          </div>
        )}
        {showFontDropdown && (
          <div className="absolute right-0">
            <Select
              value={fontOptions.find((opt) => opt.value === currentFont)}
              onChange={(selected) => {
                setCurrentFont(selected.value);
                setShowFontDropdown(false);
              }}
              options={fontOptions}
              styles={customStyles}
              classNamePrefix="font-select"
              menuPlacement="bottom"
            />
          </div>
        )}
      </div>

      {/* Theme Toggle Button */}
      <button
        onClick={toggleTheme}
        className={`${themeButtonStyle} ml-2`}
        title={`Current theme: ${theme}`}
      >
        {getThemeIcon()}
      </button>
        </div>

    </div>
  );
};

export default Toolbar;
