import React, { useRef, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeRaw from 'rehype-raw';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  FileText,
  ExternalLink,
  Volume2,
  Copy,
  Check,
  Square,
  ChevronDown,
  ChevronUp,
} from 'lucide-react';
import remarkBreaks from 'remark-breaks';
import { isDeepSeekReasoning, getChatModelByModelString } from './chatModels';

const CODE_BLOCK_MAX_HEIGHT = 400;
const MAX_IMAGE_SIZE = 300;

const CodeBlock = ({ inline, className, children, ...props }) => {
  const [copyStatus, setCopyStatus] = useState('Copy');

  const match = /language-(\w+)/.exec(className || '');
  if (!inline && match) {
    const codeString = String(children).replace(/\n$/, '');

    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(codeString);
        setCopyStatus('Copied!');
        setTimeout(() => setCopyStatus('Copy'), 2000);
      } catch (err) {
        setCopyStatus('Failed to copy');
        setTimeout(() => setCopyStatus('Copy'), 2000);
      }
    };

    return (
      <div className="relative my-3 rounded-lg overflow-hidden bg-[rgb(41,44,51)] dark:bg-gray-800">
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 text-xs bg-gray-700 text-white py-1 px-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          {copyStatus}
        </button>
        <SyntaxHighlighter
          style={oneDark}
          language={match[1]}
          PreTag="div"
          customStyle={{ margin: 0, padding: '1rem', background: 'transparent' }}
          wrapLongLines={true}
          {...props}
        >
          {codeString}
        </SyntaxHighlighter>
      </div>
    );
  } else {
    // Inline code
    return (
      <code
        className="font-mono text-sm bg-gray-100 dark:bg-gray-800 dark:text-gray-200 rounded px-1 py-0.5"
        {...props}
      >
        {children}
      </code>
    );
  }
};

const ChatWindow = ({ selectedChat, messages, selectedModel }) => {
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [copyStatus, setCopyStatus] = useState({});
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingMessageId, setSpeakingMessageId] = useState(null);
  const [audioSource, setAudioSource] = useState(null);

  // Track which messages have reasoning shown
  const [showReasoningMap, setShowReasoningMap] = useState({});

  const toggleReasoning = (messageId) => {
    setShowReasoningMap((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const chatModel = getChatModelByModelString(selectedModel);
  const isDeepSeekReasoningSelected = isDeepSeekReasoning(chatModel);

  const isImageUrl = (url) => {
    if (/\.(jpeg|jpg|gif|png|webp)$/i.test(url)) {
      return true;
    }
    if (url.includes('blob.core.windows.net') && url.includes('img-')) {
      return true;
    }
    return false;
  };

  const TypingIndicator = () => (
    <div className="flex space-x-2 p-3 bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] rounded-lg max-w-[100px]">
      <div className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce"></div>
      <div
        className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce"
        style={{ animationDelay: '0.1s' }}
      ></div>
      <div
        className="w-3 h-3 bg-[rgb(199,199,195)] dark:bg-[rgb(69,69,69)] rounded-full animate-bounce"
        style={{ animationDelay: '0.2s' }}
      ></div>
    </div>
  );

  // const ThinkingLabel = () => (
  //   <div className="flex items-center justify-start p-3 bg-transparent text-sm text-gray-500 dark:text-gray-400">
  //     Thinking...
  //   </div>
  // );
  const ThinkingLabel = () => {
    const [thinkingPhase, setThinkingPhase] = useState(0);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        // Cycle through 0 -> 1 -> 2 -> 3 -> 0 -> ...
        setThinkingPhase((prev) => (prev + 1) % 4);
      }, 500); // Adjust timing if needed
  
      return () => {
        clearInterval(intervalId);
      };
    }, []);
  
    const thinkingText = ['Thinking', 'Thinking.', 'Thinking..', 'Thinking...'][thinkingPhase];
  
    return (
      <div className="flex items-center justify-start p-3 bg-transparent text-sm text-gray-500 dark:text-gray-400">
        {thinkingText}
      </div>
    );
  };

  const getDomainName = (url) => {
    try {
      const hostname = new URL(url).hostname;
      const cleanHostname = hostname.replace(/^www\./, '');
      const parts = cleanHostname.split('.');
      return parts.length > 1 ? parts[parts.length - 2] : cleanHostname;
    } catch (error) {
      return url;
    }
  };

  const handleCopyMessage = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus((prev) => ({ ...prev, [text]: true }));
      setTimeout(() => {
        setCopyStatus((prev) => ({ ...prev, [text]: false }));
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const audioRef = useRef(null);

  const handleSpeak = async (text, messageId) => {
    // If already speaking the same message, STOP
    if (isSpeaking && speakingMessageId === messageId) {
      stopStreaming();
      return;
    }

    // Otherwise, start streaming
    stopStreaming(); // Stop any previous streaming if still in progress
    setIsSpeaking(true);
    setSpeakingMessageId(messageId);

    const mediaSource = new MediaSource();
    mediaSourceRef.current = mediaSource;

    mediaSource.addEventListener('sourceopen', async () => {
      sourceBufferRef.current = mediaSource.addSourceBuffer('audio/mpeg');

      try {
        const url = `https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/tts?text=${encodeURIComponent(
          text
        )}`;
        const response = await fetch(url);
        if (!response.body) throw new Error('No response body from TTS stream');

        const reader = response.body.getReader();
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          if (readerDone) {
            done = true;
            mediaSource.endOfStream();
            break;
          }
          // Wait for buffer update to finish before appending new chunk
          await appendBuffer(value);
        }
      } catch (err) {
        console.error('Error reading TTS stream:', err);
        if (mediaSource.readyState === 'open') {
          mediaSource.endOfStream();
        }
      }
    });

    const objectURL = URL.createObjectURL(mediaSource);
    setAudioSource(objectURL);
  };

  const appendBuffer = (chunk) => {
    return new Promise((resolve, reject) => {
      const sourceBuffer = sourceBufferRef.current;
      if (!sourceBuffer) return resolve();

      sourceBuffer.addEventListener('updateend', handleUpdateEnd, { once: true });
      sourceBuffer.addEventListener('error', handleError, { once: true });

      function handleUpdateEnd() {
        sourceBuffer.removeEventListener('error', handleError);
        resolve();
      }
      function handleError(e) {
        sourceBuffer.removeEventListener('updateend', handleUpdateEnd);
        reject(e);
      }

      try {
        sourceBuffer.appendBuffer(chunk);
      } catch (err) {
        reject(err);
      }
    });
  };

  const stopStreaming = () => {
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      mediaSourceRef.current.endOfStream();
    }
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    if (audioSource) {
      URL.revokeObjectURL(audioSource);
      setAudioSource(null);
    }
    setIsSpeaking(false);
    setSpeakingMessageId(null);
  };

  useEffect(() => {
    // Clean up on unmount
    return () => {
      stopStreaming();
    };
  }, []);

  const renderMessageContent = (message) => {
    return (
      <div
        className="relative"
        onMouseEnter={() => setHoveredMessageId(message.id)}
        onMouseLeave={() => setHoveredMessageId(null)}
      >
        {message.role === 'assistant' ? (
          isImageUrl(message.text) ? (
            <img
              src={message.text}
              alt="AI generated"
              className="max-w-full h-auto rounded-lg"
              style={{
                maxWidth: `${MAX_IMAGE_SIZE}px`,
                maxHeight: `${MAX_IMAGE_SIZE}px`,
                objectFit: 'contain',
              }}
            />
          ) : (
            <>
            {/* Toggle for Chain-of-Thought reasoning_text, if present */}
            {message.reasoning_text && (
                <div className="mt-2 mb-2">
                  <div
                    className="mb-2 flex items-center cursor-pointer text-sm text-gray-600 dark:text-gray-400"
                    onClick={() => toggleReasoning(message.id)}
                  >
                    <span className="mr-1">
                      {showReasoningMap[message.id]
                        ? `Thought for ${message.reasoning_time}`
                        : `Thought for ${message.reasoning_time}`}
                    </span>
                    {showReasoningMap[message.id] ? (
                      <ChevronUp size={16} />
                    ) : (
                      <ChevronDown size={16} />
                    )}
                  </div>
                  {showReasoningMap[message.id] && (
                    <div className="border-l-2 border-gray-300 dark:border-gray-600 pl-3 mt-1 text-xs text-gray-600 dark:text-gray-400 whitespace-pre-wrap">
                      {message.reasoning_text}
                    </div>
                  )}
                </div>
              )}
              <ReactMarkdown
                className="markdown-content leading-relaxed text-base text-black dark:text-gray-200"
                remarkPlugins={[remarkGfm, remarkBreaks, remarkMath]}
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                components={{
                  p: ({ node, ...props }) => (
                    <p className="mb-3 last:mb-0 leading-loose" {...props} />
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      className="text-blue-600 hover:underline dark:text-blue-400 break-words"
                      target="_blank"
                      rel="noopener noreferrer"
                      {...props}
                    />
                  ),
                  h1: ({ node, ...props }) => (
                    <h1
                      className="text-2xl font-bold mt-6 mb-3 border-b border-gray-200 dark:border-gray-700 pb-1"
                      {...props}
                    />
                  ),
                  h2: ({ node, ...props }) => (
                    <h2 className="text-xl font-semibold mt-5 mb-2" {...props} />
                  ),
                  h3: ({ node, ...props }) => (
                    <h3 className="text-lg font-medium mt-4 mb-1" {...props} />
                  ),
                  h4: ({ node, ...props }) => (
                    <h4 className="text-base font-medium mt-3 mb-1" {...props} />
                  ),
                  blockquote: ({ node, ...props }) => (
                    <blockquote
                      className="border-l-4 border-gray-300 dark:border-gray-600 pl-4 italic text-gray-700 dark:text-gray-300 my-3"
                      {...props}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul className="list-disc list-inside mb-3" {...props} />
                  ),
                  ol: ({ node, ...props }) => (
                    <ol className="list-decimal list-inside mb-3" {...props} />
                  ),
                  li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                  table: ({ node, ...props }) => (
                    <table
                      className="border-collapse border border-gray-300 dark:border-gray-600 mt-4 mb-4 w-full text-left"
                      {...props}
                    />
                  ),
                  thead: ({ node, ...props }) => (
                    <thead className="bg-gray-100 dark:bg-gray-700" {...props} />
                  ),
                  tr: ({ node, ...props }) => (
                    <tr
                      className="border border-gray-300 dark:border-gray-600"
                      {...props}
                    />
                  ),
                  th: ({ node, ...props }) => (
                    <th
                      className="px-3 py-2 border border-gray-300 dark:border-gray-600 font-semibold bg-gray-50 dark:bg-gray-800"
                      {...props}
                    />
                  ),
                  td: ({ node, ...props }) => (
                    <td
                      className="px-3 py-2 border border-gray-300 dark:border-gray-600"
                      {...props}
                    />
                  ),
                  code: CodeBlock, // Our custom code block
                  img: ({ node, ...props }) => (
                    <img
                      {...props}
                      className="my-3 max-w-full h-auto rounded-lg border border-gray-200 dark:border-gray-700"
                      style={{
                        maxWidth: `${MAX_IMAGE_SIZE}px`,
                        maxHeight: `${MAX_IMAGE_SIZE}px`,
                        objectFit: 'contain',
                      }}
                    />
                  ),
                }}
              >
                {message.text}
              </ReactMarkdown>

              {/* Action buttons - show/hide on hover (assistant only) */}
              {!isImageUrl(message.text) && (
                <div
                  className={`flex space-x-1 mt-2 transition-opacity duration-200 ${
                    hoveredMessageId === message.id ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <button
                    onClick={() => handleSpeak(message.text, message.id)}
                    className="pt-1.5 pb-1.5 pr-2 rounded-full text-gray-600 dark:text-gray-400"
                    title={
                      isSpeaking && speakingMessageId === message.id
                        ? 'Stop'
                        : 'Read aloud'
                    }
                  >
                    {isSpeaking && speakingMessageId === message.id ? (
                      <Square size={16} />
                    ) : (
                      <Volume2 size={16} />
                    )}
                  </button>
                  <button
                    onClick={() => handleCopyMessage(message.text)}
                    className="p-1.5 rounded-full text-gray-600 dark:text-gray-400"
                    title="Copy"
                  >
                    {copyStatus[message.text] ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              )}
            </>
          )
        ) : (
          // User text
          <div>{message.text}</div>
        )}

        {/* Citations (if any) */}
        {message.citations && message.citations.length > 0 && (
          <div className="mt-3 flex flex-wrap gap-2">
            {message.citations.map((citation, index) => {
              const domain = getDomainName(citation);
              return (
                <a
                  key={index}
                  href={citation}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-3 py-1 rounded-full bg-[rgb(219,219,215)] dark:bg-[rgb(35,35,35)] text-black dark:text-white text-sm hover:bg-[rgb(200,200,196)] dark:hover:bg-[rgb(50,50,50)] transition-colors"
                >
                  {index + 1}. {domain}
                </a>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderAttachments = (message) => {
    if (message.role !== 'user') return null;

    if (message.imageUrl) {
      return (
        <div className="relative max-w-full overflow-hidden mb-2">
          <img
            src={message.imageUrl}
            alt="User uploaded"
            className="max-w-full h-auto rounded-lg"
            style={{
              maxWidth: `${MAX_IMAGE_SIZE}px`,
              maxHeight: `${MAX_IMAGE_SIZE}px`,
              objectFit: 'contain',
            }}
          />
          {message.localImage && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm rounded-lg">
              Uploading...
            </div>
          )}
        </div>
      );
    }

    if (message.pdfUrl) {
      return (
        <div className="flex items-center gap-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-lg mb-2 max-w-md">
          <FileText className="text-gray-600 dark:text-gray-400" size={24} />
          <div className="flex flex-col flex-grow">
            <span className="text-sm text-gray-700 dark:text-gray-300">
              {message.pdfName || 'PDF Document'}
            </span>
            <a
              href={message.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 text-sm flex items-center gap-1"
            >
              Open PDF <ExternalLink size={14} />
            </a>
          </div>
        </div>
      );
    }

    return null;
  };

  // Handle autoscroll logic
  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 10;
      setAutoScrollEnabled(isAtBottom);
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [chatContainerRef]);

  // Auto-scroll to bottom when new messages arrive (if autoScrollEnabled)
  useEffect(() => {
    if (autoScrollEnabled && chatContainerRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, autoScrollEnabled]);

  return (
    <div className="flex flex-col h-full">
      {messages.length === 0 ? (
        <div className="h-full flex items-center justify-center">
          <p className="text-xl text-gray-400 dark:text-gray-500 font-medium">
            New Chat
          </p>
        </div>
      ) : (
        <div
          ref={chatContainerRef}
          className="flex-grow overflow-y-auto p-4 scroll-smooth"
          style={{ scrollBehavior: 'smooth' }}
        >
          <div className="max-w-3xl mx-auto space-y-4">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.role === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`inline-block px-6 py-2 rounded-3xl ${
                    message.role === 'user'
                      ? 'bg-[rgb(239,239,235)] dark:bg-[rgb(35,35,35)] text-black dark:text-white max-w-[75%]'
                      : 'text-black dark:text-white max-w-[85%]'
                  } break-words ${message.pending ? 'opacity-50' : ''}`}
                >
                  {renderAttachments(message)}
                  {renderMessageContent(message)}
                  {message.pending && (
                    <span className="text-xs italic"> (Sending...)</span>
                  )}
                  {message.error && (
                    <span className="text-xs text-red-500">
                      {' '}
                      (Error sending message)
                    </span>
                  )}
                </div>
              </div>
            ))}
            {(
              messages[messages.length - 1]?.role === 'user' ||
              (messages[messages.length - 1]?.role === 'assistant' &&
                messages[messages.length - 1]?.text === '')
            ) && (
              <div className="flex justify-start">
                {isDeepSeekReasoningSelected ? <ThinkingLabel /> : <TypingIndicator />}
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
      )}

      {/* Hidden audio element to play TTS */}
      <div className="hidden">
        <audio ref={audioRef} src={audioSource || ''} controls autoPlay />
      </div>
    </div>
  );
};

export default ChatWindow;
