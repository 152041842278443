import React, { useState, useEffect } from 'react';
import { Sun, Moon, Monitor, LogOut } from 'lucide-react';
import { useTheme } from './ThemeContext';
import { useFont } from './FontContext';
import Select from 'react-select';

const Settings = ({ userName, userEmail, hasSubscription, onLogout, onUpgrade }) => {
  const [activeSection, setActiveSection] = useState('general');
  const { theme, setTheme } = useTheme();
  const { currentFont, setCurrentFont } = useFont();

  const fonts = [
    { name: 'PT Serif', className: 'font-serif' },
    { name: 'Inter', className: 'font-sans' },
    { name: 'Funnel Display', className: 'font-sans' },
    { name: 'Sour Gummy', className: 'font-sans' },
    { name: 'DynaPuff', className: 'font-sans' },
    { name: 'Yusei Magic', className: 'font-sans' },
  ];

  const fontOptions = fonts.map((font) => ({
    value: font.name,
    label: font.name,
  }));

  // Conditional styling for dark vs light mode
  const isDark = theme === 'dark';
  const isDarkMode = theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);

  const darkBg = '#242424';
  const darkBgHover = '#1e1e1e';
  const lightBg = '#FFFFFF';
  const lightBgHover = '#F2F2F2';
  const darkText = '#CBD5E0';
  const lightText = '#4A5568';

  const customStyles = {
    // The container that wraps the control
    container: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
    }),
    // The control is the clickable area that shows the current value
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      borderColor: isDarkMode ? '#242424' : '#CBD5E0',
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        borderColor: isDarkMode ? '#718096' : '#A0AEC0',
      },
      boxShadow: state.isFocused ? (isDarkMode ? '0 0 0 1px #718096' : '0 0 0 1px #A0AEC0') : null,
    }),
    // The dropdown menu
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      zIndex: 9999,
    }),
    // Individual options in the list
    option: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      backgroundColor: state.isFocused
        ? isDarkMode ? darkBgHover : lightBgHover
        : isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
      cursor: 'pointer'
    }),
    // The currently selected value
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: state.data.value,
      color: isDarkMode ? darkText : lightText,
    }),
    // The placeholder text
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#718096' : '#A0AEC0',
    }),
    // The indicators (dropdown arrow, clear button, etc.)
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: isDarkMode ? darkText : lightText,
      ':hover': {
        color: isDarkMode ? '#FFFFFF' : '#2D3748',
      },
    }),
    // The value container holding the selected value
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? darkBg : lightBg,
      color: isDarkMode ? darkText : lightText,
    }),
  };

  const handleUnsubscribe = () => {
    window.location.href = 'https://billing.stripe.com/p/login/test_3cs9AR4TwfcFb4YbII';
  };

  useEffect(() => {
    if (theme === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = () => {
        document.documentElement.classList.toggle('dark', mediaQuery.matches);
      };
      mediaQuery.addListener(handleChange);
      handleChange();
      return () => mediaQuery.removeListener(handleChange);
    }
  }, [theme]);

  const GeneralSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">General</h2>
      <div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Name</p>
        <p className="mt-1 text-lg text-gray-900 dark:text-gray-100">{userName}</p>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Email</p>
        <p className="mt-1 text-lg text-gray-900 dark:text-gray-100">{userEmail}</p>
      </div>

      {/* Appearance Controls */}
      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Appearance</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Adjust the appearance of the interface</p>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => setTheme('light')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'light'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Sun className="mr-2" size={16} />
            Light
          </button>
          <button
            onClick={() => setTheme('dark')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'dark'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Moon className="mr-2" size={16} />
            Dark
          </button>
          <button
            onClick={() => setTheme('system')}
            className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
              theme === 'system'
                ? 'bg-blue-500 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600'
            }`}
          >
            <Monitor className="mr-2" size={16} />
            System
          </button>
        </div>
      </div>

      {/* Font Selection using React-Select */}
      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Font</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Choose your preferred font</p>
        </div>
        <div className="relative inline-block" style={{ width: 200 }}>
          <Select
            value={fontOptions.find((opt) => opt.value === currentFont)}
            onChange={(selected) => setCurrentFont(selected.value)}
            options={fontOptions}
            styles={customStyles} // Use the new dark mode-aware styles
            classNamePrefix="font-select"
            menuPlacement="auto"
          />
        </div>
      </div>

      <div className="flex items-center justify-between py-4 border-t border-gray-200 dark:border-gray-700">
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Log out of my account</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Terminate your current session on this device</p>
        </div>
        <button
          onClick={onLogout}
          className="flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-white border border-red-600 rounded-md hover:bg-red-50 dark:bg-gray-700 dark:text-red-400 dark:border-red-400 dark:hover:bg-gray-600"
        >
          <LogOut className="mr-2" size={16} />
          Log Out
        </button>
      </div>
    </div>
  );

  const SubscriptionSection = () => (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Subscription</h2>
      <div className="bg-gradient-to-br from-blue-400 to-purple-500 text-white rounded-lg p-6">
        <h3 className="text-2xl font-bold mb-2">Get unlimited access to ArrowAI</h3>
        <p className="mb-4">Get access to the premium AI from OpenAI, Anthropic, Google, and Perplexity in one place.</p>
        {hasSubscription ? (
          <button onClick={handleUnsubscribe} className="px-4 py-2 text-sm font-medium bg-gray-300 text-gray-600 rounded-md">
            Unsubscribe
          </button>
        ) : (
          <button onClick={onUpgrade} className="px-4 py-2 text-sm font-medium bg-white text-purple-600 rounded-md hover:bg-gray-50">
            Upgrade to Pro
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex justify-center min-h-screen bg-transparent">
      <div className="w-full max-w-4xl bg-transparent dark:bg-transparent overflow-hidden pt-16">
        <div className="flex">
          <div className="w-1/4 bg-transparent dark:bg-transparent p-8">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-6">Settings</h2>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => setActiveSection('general')}
                  className={`w-full text-left px-4 py-2 rounded-md ${
                    activeSection === 'general'
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                >
                  General
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveSection('subscription')}
                  className={`w-full text-left px-4 py-2 rounded-md ${
                    activeSection === 'subscription'
                      ? 'bg-blue-500 text-white'
                      : 'text-gray-600 dark:text-gray-300 hover:bg-[rgb(204,204,201)] dark:hover:bg-gray-600'
                  }`}
                >
                  Subscription
                </button>
              </li>
            </ul>
          </div>
          <div className="w-3/4 p-8">
            {activeSection === 'general' && <GeneralSection />}
            {activeSection === 'subscription' && <SubscriptionSection />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
