import React, { createContext, useContext, useState, useEffect } from 'react';

const FontContext = createContext();

export const FontProvider = ({ children }) => {
  const [currentFont, setCurrentFont] = useState(() => {
    // Try to get saved font from localStorage, default to 'PT Serif'
    return localStorage.getItem('preferred-font') || 'PT Serif';
  });

  useEffect(() => {
    // Update localStorage when font changes
    localStorage.setItem('preferred-font', currentFont);
    // Update body font-family
    document.body.style.fontFamily = `${currentFont}, serif`;
  }, [currentFont]);

  return (
    <FontContext.Provider value={{ currentFont, setCurrentFont }}>
      {children}
    </FontContext.Provider>
  );
};

export const useFont = () => {
  const context = useContext(FontContext);
  if (!context) {
    throw new Error('useFont must be used within a FontProvider');
  }
  return context;
};