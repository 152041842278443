import React, { useEffect, useRef } from 'react';

class Bird {
  constructor(x, y) {
    this.position = { x, y };
    this.velocity = {
      x: Math.random() * 2 - 1,
      y: Math.random() * 2 - 1
    };
    this.acceleration = { x: 0, y: 0 };
    this.maxSpeed = 3;
    this.maxForce = 0.05;
  }

  align(birds) {
    let perception = 50;
    let steering = { x: 0, y: 0 };
    let total = 0;
    
    birds.forEach(bird => {
      let d = Math.hypot(this.position.x - bird.position.x, this.position.y - bird.position.y);
      if (bird !== this && d < perception) {
        steering.x += bird.velocity.x;
        steering.y += bird.velocity.y;
        total++;
      }
    });

    if (total > 0) {
      steering.x /= total;
      steering.y /= total;
      let mag = Math.hypot(steering.x, steering.y);
      steering.x = (steering.x / mag) * this.maxSpeed;
      steering.y = (steering.y / mag) * this.maxSpeed;
      steering.x -= this.velocity.x;
      steering.y -= this.velocity.y;
    }
    return steering;
  }

  update() {
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;
    this.velocity.x += this.acceleration.x;
    this.velocity.y += this.acceleration.y;
    
    let speed = Math.hypot(this.velocity.x, this.velocity.y);
    if (speed > this.maxSpeed) {
      this.velocity.x = (this.velocity.x / speed) * this.maxSpeed;
      this.velocity.y = (this.velocity.y / speed) * this.maxSpeed;
    }
    
    this.acceleration.x = 0;
    this.acceleration.y = 0;
  }

  edges(width, height) {
    if (this.position.x > width) this.position.x = 0;
    if (this.position.x < 0) this.position.x = width;
    if (this.position.y > height) this.position.y = 0;
    if (this.position.y < 0) this.position.y = height;
  }
}

const UpdatingWebsite = () => {
  const canvasRef = useRef(null);
  const birds = useRef([]);
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    // Initialize birds
    const initBirds = () => {
      birds.current = [];
      for (let i = 0; i < 150; i++) {
        birds.current.push(new Bird(
          Math.random() * canvas.width,
          Math.random() * canvas.height
        ));
      }
    };

    const render = () => {
      ctx.fillStyle = isDark ? 'rgb(24,24,24)' : 'rgb(229,229,225)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      birds.current.forEach(bird => {
        // Update bird position
        bird.align(birds.current);
        bird.update();
        bird.edges(canvas.width, canvas.height);

        // Draw bird as a dot
        ctx.fillStyle = isDark ? 'rgb(156,163,175)' : 'rgb(75,85,99)';
        ctx.beginPath();
        ctx.arc(bird.position.x, bird.position.y, 2, 0, Math.PI * 2);
        ctx.fill();
      });

      animationFrameId = window.requestAnimationFrame(render);
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();
    initBirds();
    render();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [isDark]);

  return (
    <div className="fixed inset-0 overflow-hidden">
      <canvas
        ref={canvasRef}
        className="absolute inset-0 -z-10"
      />
      <div className="relative h-full flex items-center justify-center">
        <div className="flex flex-col items-center space-y-6 p-8 text-center">
          <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200">
            Arrow.AI is getting some upgrades.
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            We will return on January 3rd.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdatingWebsite;
