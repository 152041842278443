// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, get, runTransaction } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export const firebaseConfig = {
    apiKey: "AIzaSyDhQ4o_AGXsS7mz5BicE1QNNzgMEpuafzs",
    authDomain: "arrowai.firebaseapp.com",
    databaseURL: "https://arrowai-default-rtdb.firebaseio.com",
    projectId: "arrowai",
    storageBucket: "arrowai.appspot.com",
    messagingSenderId: "788979976897",
    appId: "1:788979976897:web:30734279551762e3096240",
    measurementId: "G-RCZR3LY9PQ"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    await checkUserExistence(user, user.email, user.displayName);
    return user;
  } catch (error) {
    throw error;
  }
};

export const signInWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider);
    const user = result.user;
    const fullName = user.displayName || '';
    await checkUserExistence(user, user.email, fullName);
    return user;
  } catch (error) {
    throw error;
  }
};

export const signUpWithEmail = async (email, password, fullName) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const user = result.user;
    await saveNewUser(user, email, fullName);
    return user;
  } catch (error) {
    throw error;
  }
};

export const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    throw error;
  }
};

const checkUserExistence = async (user, email, fullName) => {
  const userRef = ref(database, `users/${user.uid}`);
  const snapshot = await get(userRef);
  
  if (!snapshot.exists()) {
    await saveNewUser(user, email, fullName);
  }
};

const saveNewUser = async (user, email, fullName) => {
  const userRef = ref(database, `users/${user.uid}`);
  const userData = {
    uid: user.uid,
    email: email || '',
    fullName: fullName || '',
    isSubscribed: false,
    subscriptionType: 'free',
    subscribedOn: 'website',
  };
  
  await set(userRef, userData);
  await incrementUserCount();
};

const incrementUserCount = async () => {
  const configRef = ref(database, 'config');
  await runTransaction(configRef, (currentData) => {
    if (!currentData) currentData = {};
    if (!currentData.userCount) currentData.userCount = 0;
    currentData.userCount++;
    return currentData;
  });
};

export { auth, database };